import '../sass/frontend.scss'
import $ from 'jquery'
import {slick} from "slick-carousel"

$(document).ready(function () {
    initiate_slider();
});


function initiate_slider(){
    var home_slider = $('.home-header-slider');
    if (home_slider.length) {
     home_slider.slick({
      arrows: true,
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: `<div class="next-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
      <path d="M16.7325 5.61222L11.3367 0.216383C11.1913 0.0759689 10.9966 -0.00172714 10.7944 2.91394e-05C10.5923 0.00178542 10.399 0.0828534 10.2561 0.225772C10.1132 0.368691 10.0321 0.562026 10.0303 0.764136C10.0286 0.966246 10.1063 1.16096 10.2467 1.30634L14.3267 5.38637H0.770834C0.566396 5.38637 0.370331 5.46758 0.225772 5.61214C0.0812126 5.7567 0 5.95276 0 6.1572C0 6.36164 0.0812126 6.5577 0.225772 6.70226C0.370331 6.84682 0.566396 6.92803 0.770834 6.92803H14.3267L10.2467 11.0081C10.1731 11.0792 10.1143 11.1642 10.074 11.2583C10.0336 11.3523 10.0123 11.4535 10.0114 11.5558C10.0105 11.6582 10.03 11.7597 10.0688 11.8544C10.1075 11.9491 10.1648 12.0352 10.2371 12.1076C10.3095 12.1799 10.3956 12.2372 10.4903 12.2759C10.585 12.3147 10.6866 12.3342 10.7889 12.3333C10.8913 12.3324 10.9924 12.3112 11.0864 12.2708C11.1805 12.2304 11.2655 12.1716 11.3367 12.098L16.7325 6.70218C16.877 6.55763 16.9582 6.3616 16.9582 6.1572C16.9582 5.9528 16.877 5.75677 16.7325 5.61222V5.61222Z" fill="black"/>
      </svg></div>`,
      prevArrow: `<div class="prev-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
      <path d="M0.225699 6.72079L5.62154 12.1166C5.76692 12.257 5.96163 12.3347 6.16374 12.333C6.36585 12.3312 6.55919 12.2502 6.70211 12.1072C6.84503 11.9643 6.92609 11.771 6.92785 11.5689C6.92961 11.3668 6.85191 11.172 6.7115 11.0267L2.63147 6.94664L16.1874 6.94664C16.3918 6.94664 16.5879 6.86543 16.7324 6.72087C16.877 6.57631 16.9582 6.38025 16.9582 6.17581C16.9582 5.97137 16.877 5.77531 16.7324 5.63075C16.5879 5.48619 16.3918 5.40497 16.1874 5.40497L2.63147 5.40497L6.7115 1.32495C6.78512 1.25384 6.84384 1.16879 6.88424 1.07474C6.92464 0.980697 6.9459 0.879548 6.94679 0.777197C6.94768 0.674847 6.92818 0.573345 6.88942 0.478612C6.85066 0.383879 6.79343 0.297814 6.72105 0.225438C6.64867 0.153062 6.56261 0.0958247 6.46788 0.0570665C6.37314 0.0183083 6.27164 -0.00119495 6.16929 -0.000305553C6.06694 0.000583847 5.96579 0.021849 5.87175 0.0622477C5.7777 0.102646 5.69264 0.161369 5.62154 0.234992L0.225699 5.63083C0.0811903 5.77538 1.02869e-05 5.97141 1.02869e-05 6.17581C1.02869e-05 6.38021 0.0811903 6.57624 0.225699 6.72079V6.72079Z" fill="black"/>
      </svg></div>`,
     });
        $('.home-header-slider').addClass('loaded');
    }
   }

   $('.single-product .related ul.products').wrapAll('<div class="glide__track"></div>');